import React, { useState, useEffect } from 'react'
import axios from "axios";
import { useRef } from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
export default function Footer() {
    // Aos Animation
    useEffect(() => {
        AOS.init({
            duration: 1000, // Animation duration
            offset: 100,    // Animation starts when the element is 100px into view
            easing: "ease-in-out",
            once: false,    // Ensures animation triggers every time element appears in viewport
        });
    }, []);

    const [showButton, setShowButton] = useState(false);

    // Check the scroll position
    const handleScroll = () => {
        if (window.scrollY > 300) {
            setShowButton(true); // Show button after scrolling 300px
        } else {
            setShowButton(false); // Hide button when near top
        }
    };

    // Add event listener for scrolling
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener when component is unmounted
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Smooth scroll
        });
    }, []);

    // Function to scroll back to the top
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Smooth scroll
        });
    };

    const Mycss = {
        scrollButton: {
            position: 'fixed',
            bottom: '20px',
            right: '20px',
            backgroundColor: 'rgb(70 147 185)',
            color: '#fff',
            border: 'none',
            padding: '5px 10px',
            borderRadius: '20px',
            cursor: 'pointer',
            fontSize: '16px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            zIndex: '999',
        },
    };
    const [hover, setHover] = useState(false);
    const [formError, setFormError] = useState('');
    const [message, setMessage] = useState('');
    const [name, setName] = useState('');
    const [type, setType] = useState('SCAi');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [description, setDescription] = useState('');

    const formSubmit = async () => {
        if (name && email && mobile && description) {
            setFormError(""); // Clear errors on success
            const formData = {
                type,
                name,
                email,
                mobile,
                description,
            };

            try {
                const response = await axios.post(
                    "https://fixa.co.in/arj_dev/api/scai/enquiry/post",
                    formData
                );
                console.log("Response:", response.data);
                setMessage("Form submitted successfully!");
                setFormError(""); // Clear errors on success
            } catch (error) {
                console.error("Error submitting form:", error);
                setMessage("Failed to submit form.");
            }
        } else {
            setFormError("Please fill in all the details.");
        }
    };
    // Chat with SCAI -> funcintily
    const [messages, setMessages] = useState([
        { text: "Hello! 👋 I am SCAI, your School Management Assistant.", sender: "bot" },
        { text: "How can I assist you?", sender: "bot" },
        // { text: "Tap below to select an option.", sender: "bot", isOptions: true }
    ]);
    const [isOpen, setIsOpen] = useState(false);
    const [showOptions, setShowOptions] = useState(true);
    const [loading, setLoading] = useState(false);
    const chatEndRef = useRef(null);

    // Predefined questions
    const options = [
        "Student Management",
        "Attendance Tracking",
        "Fee & Finance Management",
        "Examination & Results",
        "Timetable & Scheduling",
        "Library Management",
        "Transport & GPS Tracking",
        "Parent & Teacher Communication",
        "Hostel & Boarding Management",
        "HR & Payroll"
    ];

    // Predefined auto-replies
    const responses = {
        "Student Management": "Manage student admissions, records, grades, and performance analytics.",
        "Attendance Tracking": "Track daily attendance for students and staff with automated reporting.",
        "Fee & Finance Management": "Manage fee collection, pending dues, and generate financial reports.",
        "Examination & Results": "Create exam schedules, manage grading, and generate report cards.",
        "Timetable & Scheduling": "Generate dynamic timetables and schedule classes efficiently.",
        "Library Management": "Track issued/returned books and manage library resources.",
        "Transport & GPS Tracking": "Monitor school transport routes with real-time GPS tracking.",
        "Parent & Teacher Communication": "Enable seamless communication through messaging and notifications.",
        "Hostel & Boarding Management": "Manage hostel accommodations, meal plans, and student assignments.",
        "HR & Payroll": "Handle staff payroll, attendance, leaves, and salary management."
    };

    // Scroll to bottom when a new message is added
    useEffect(() => {
        chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages]);

    // Handle user message selection
    const handleSelectMessage = (selectedMessage) => {
        const newMessage = { text: selectedMessage, sender: "user" };
        setMessages([...messages, newMessage]);
        setShowOptions(false);
        setLoading(true);

        // Simulate AI bot reply with 2-sec loading
        setTimeout(() => {
            setMessages((prev) => [
                ...prev,
                { text: responses[selectedMessage], sender: "bot" }
            ]);
            setLoading(false);
        }, 2000);
    };


    return (

        <>
            {/* inquery form */}
            {/* Side panel form */}
            <div
                className={`inquiry-form-container ${hover ? 'hovered' : ''}`}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                <div className="inquiry-form-trigger">
                    BOOK A DEMO  <i className="fa fa-question-circle" aria-hidden="true"></i>
                </div>
                <div className="inquiry-form">
                    <h3 className='text-center text-light mb-3'>Scai Demo</h3>
                    {formError ? (<div class="alert alert-danger" role="alert">
                        {formError}
                    </div>) : ''}
                    {message ? (<div class="alert alert-success" role="alert">
                        {message}
                    </div>) : ''}
                    <div className="form-floating form-floating-outline">
                        <label htmlFor="floatingInput text-white">Name</label>
                        <input type="text" className="form-control" placeholder="Name" onKeyUp={(e) => setName(e.target.value)} />
                    </div>
                    <div className="form-floating form-floating-outline">
                        <label htmlFor="floatingInput text-white">Email address</label>
                        <input type="email" className="form-control" placeholder="Email" onKeyUp={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className="form-floating form-floating-outline">
                        <label htmlFor="floatingInput text-white">Mobile</label>
                        <input type="number" className="form-control" placeholder="Mobile" required onKeyUp={(e) => setMobile(e.target.value)} />
                    </div>
                    <div className="form-floating form-floating-outline">
                        <label htmlFor="floatingInput text-white">Your Message</label>
                        <textarea placeholder="Your Message" className='form-control' onKeyUp={(e) => setDescription(e.target.value)}  ></textarea>
                    </div>
                    <button type="button" onClick={formSubmit} className="btn btn-primary">
                        Submit
                    </button>
                </div>
            </div>
            {/* Side panel form // */}

            <footer>
                <div className="container py-4">
                    <div className="row py-xl-5 py-sm-3">
                        <div className="col-lg-6 map" data-aos="fade-right">
                            <h2 className="contact-title text-capitalize text-white font-weight-light mb-sm-4 mb-3">
                                our
                                <span className="font-weight-bold">directions</span>
                            </h2>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1774.8848873158634!2d75.722915!3d27.163533!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x396dab218f50992f%3A0x180e014178bd765!2sARJ%20INSTITUTE%20OF%20TECHNOLOGY!5e0!3m2!1sen!2sin!4v1727024240933!5m2!1sen!2sin" width="800" height="600" loading="lazy" referrerPolicy="no-referrer-when-downgrade" style={{ Border: 0 }} />
                            <div className="conta-posi-w3ls p-4 rounded">
                                <h5 className="text-white font-weight-bold mb-3">Address</h5>
                                <p>
                                    3rd Floor, Above NBC Boring Chauraha, Jhotwara, Jaipur
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6 contact-agileits-w3layouts mt-lg-0 mt-4" data-aos="fade-up">
                            <h4 className="contact-title text-capitalize text-white font-weight-light mb-sm-4 mb-3">
                                get in
                                <span className="font-weight-bold">touch</span>
                            </h4>
                            <p className="conta-para-style border-left pl-4">
                                If you have any questions about the services we provide simply use
                                the form below. We try and respond to all queries and comments
                                within 24 hours.
                            </p>
                            <div className="subscribe-w3ls my-xl-5 my-4">
                                <h6 className="text-white text-capitalize mb-4">
                                    subscribe our newsletter
                                </h6>
                                <form action="#" className="subscribe_form">
                                    <input
                                        className="form-control"
                                        type="email"
                                        name="email"
                                        placeholder="Enter your email..."
                                        required=""
                                    />
                                    <button type="submit" className="btn btn-primary submit">
                                        Submit
                                    </button>
                                </form>
                            </div>
                            <p className="para-agileits-w3layouts text-white">
                                <i className="fa fa-map-marker pr-3" />
                                3rd Floor, Above NBC Boring Chauraha, Jhotwara, Jaipur
                            </p>
                            <p className="para-agileits-w3layouts text-white my-sm-3 my-2">
                                <i className="fa fa-phone pr-3" />
                                +91 7733095696
                            </p>
                            <p className="para-agileits-w3layouts">
                                <i className="fa fa-envelope-open pr-2" />
                                <a
                                    href="mailto:contact@arjtechnologies.com"
                                    className=" text-white"
                                >
                                    <span
                                    >
                                        contact@arjtechnologies.com

                                    </span>
                                </a>
                            </p>
                            <hr />
                            <p className="para-agileits-w3layouts"><a href="/"className="text-white"><span>Home</span></a></p>
                            <p className="para-agileits-w3layouts"><a href="/privacy-policy"className="text-white"><span>Privacy Policy</span></a></p>
                        </div>
                    </div>
                </div>
                <div className="copyright-agiles py-3">
                    <div className="container">
                        <div className="row">
                            <p className="col-lg-12 copy-right-grids text-white text-lg-center text-center mt-lg-1">
                                © 2024 SCAI. All Rights Reserved || Powered by
                                <a href="https://arjtechnologies.com/" target="_blank">
                                    &nbsp; Arj Technologies
                                </a>
                            </p>

                        </div>
                    </div>
                </div>
            </footer>
            {/* //footer */}
            <section className="whatsapp_icon">
                <a href="https://wa.me/917733095696" target="blank">
                    {/* <img src="assets/images/WhatsApp_icon.png" alt="Awesome Image" /> */}
                    <img src="assets/gif/icons8-whatsapp.gif" alt="Awesome Image" className='img-fluid' />
                </a>
            </section>
            <div>
                {/* Scroll-to-top button */}
                {showButton && (
                    <button
                        onClick={scrollToTop}
                        style={Mycss.scrollButton} // Inline style for button
                    >
                        <i className="fa fa-arrow-up" aria-hidden="true"></i>

                    </button>
                )}
            </div>

            {/* Header demo form direct db save */}

            <div
                className="modal fade"
                id="exampleModal"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2 className="modal-title fs-5" id="exampleModalLabel">
                                <center>Scai Software Demo Registration</center>
                            </h2>
                            <button
                                type="button"
                                className="btn-close btn btn-info"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <i class="fa fa-times" aria-hidden="true"></i>
                            </button>
                        </div>
                        <div className="modal-body">
                            {formError ? (<div class="alert alert-danger" role="alert">
                                {formError}
                            </div>) : ''}
                            {message ? (<div class="alert alert-success" role="alert">
                                {message}
                            </div>) : ''}
                            <div className="form-floating form-floating-outline">
                                <label htmlFor="floatingInput text-white">Name</label>
                                <input type="text" className="form-control" placeholder="Name" onKeyUp={(e) => setName(e.target.value)} />
                            </div>
                            <div className="form-floating form-floating-outline">
                                <label htmlFor="floatingInput text-white">Email address</label>
                                <input type="email" className="form-control" placeholder="Email" onKeyUp={(e) => setEmail(e.target.value)} />
                            </div>
                            <div className="form-floating form-floating-outline">
                                <label htmlFor="floatingInput text-white">Mobile</label>
                                <input type="number" className="form-control" placeholder="Mobile" required onKeyUp={(e) => setMobile(e.target.value)} />
                            </div>
                            <div className="form-floating form-floating-outline">
                                <label htmlFor="floatingInput text-white">Your Message</label>
                                <textarea placeholder="Your Message" className='form-control' onKeyUp={(e) => setDescription(e.target.value)}  ></textarea>
                            </div>
                        </div>
                        <div className="modal-footer">
                            {/* <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                                Close
                            </button> */}
                            <button type="button" onClick={formSubmit} className="btn btn-primary">
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Header demo form direct db save // */}

            {/* Chat With Scai */}
            <div>
                {/* Chat Toggle Button */}
                <button className="chat-toggle-button" onClick={() => setIsOpen(!isOpen)}>
                    {/* 💬 */}
                    <img src="assets/gif/icons8-chat.gif" alt="" width={"20"} />
                </button>

                {/* Chat Window */}
                <div className={`chat-container ${isOpen ? "open" : "closed"}`}>
                    {/* Chat Header */}
                    <div className="chat-header">
                        Scai AI Assistant
                        <button className="close-btn" onClick={() => setIsOpen(false)}>✖</button>
                    </div>

                    {/* Chat Messages */}
                    <div className="chat-box">
                        {messages.map((msg, index) => (
                            <div key={index} className={`message ${msg.sender}`}>
                                {msg.text}
                            </div>
                        ))}
                        {loading && <p>Loading...</p>}
                        <div ref={chatEndRef} />
                    </div>

                    {/* Chat Options */}
                    {showOptions ? (
                        <div className="chat-options">
                            {options.map((option, index) => (
                                <button key={index} className="chat-option-btn" onClick={() => handleSelectMessage(option)}>
                                    {option}
                                </button>
                            ))}
                            <button className="toggle-btn btn btn-info" onClick={() => setShowOptions(false)}>🔽</button>
                        </div>
                    ) : (
                        <button className="toggle-btn  btn btn-info" onClick={() => setShowOptions(true)}>
                            🔼

                        </button>
                    )}
                </div>
            </div>

        </>
    )
}

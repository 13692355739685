import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';
export default function Header() {
    const [showModal, setShowModal] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [description, setDescription] = useState('');
    const [formError, setFormError] = useState('');
    const [message, setMessage] = useState('');

    useEffect(() => {
        // Open modal after 10 seconds
        const timer = setTimeout(() => {
            setShowModal(true);
        }, 10000); // Set to 10 seconds

        return () => clearTimeout(timer); // Cleanup the timer on component unmount
    }, []);

    const closeModal = () => {
        setShowModal(false);
    };

    const formSubmit = (e) => {
        e.preventDefault(); // Prevent default form submission behavior

        if (!name || !email || !mobile || !description) {
            setFormError('All fields are required.');
            setMessage('');
        } else {
            Swal.fire({
                title: "Registration Successful!",
                text: "😊 Thank you for registering for a demo. We will contact you shortly.",
                icon: "success",
                confirmButtonText: "OK",
            }).then(() => {
                closeModal();
            });
        }
    };


    return (
        <>
            {/* header */}
            <header id='scrollzipPoint'>
                {/* top header */}
                <div className="top-head-w3ls py-2 bg-dark">
                    <div className="container">
                        <div className="row">
                            <h1 className="text-capitalize text-white col-7 mt-3">
                                <i className="fa fa-book text-dark bg-white p-2 rounded-circle mr-3" />
                                Welcome to SCAI School Management AI Software
                            </h1>
                            {/* social icons */}
                            <div className="social-icons text-right col-5">
                                <ul className="list-unstyled">
                                    <li>
                                        <a
                                            href="https://arjtechnologies.com/" target='_blank'
                                            className="fa fa-google icon-border facebook rounded-circle"
                                        >
                                            {" "}
                                        </a>
                                    </li>
                                    <li className="mx-2">
                                        <a
                                            href="https://www.linkedin.com/company/arj-technologies/" target='_blank'
                                            className="fa fa-linkedin icon-border twitter rounded-circle"
                                        >
                                            {" "}
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://www.instagram.com/arj_technologies/" target='_blank'
                                            className="fa fa-instagram icon-border googleplus rounded-circle"
                                        >
                                            {" "}
                                        </a>
                                    </li>
                                    <li className="ml-2">
                                        <a
                                            href="#"
                                            className="fa fa-facebook icon-border rss rounded-circle"
                                        >
                                            {" "}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            {/* //social icons */}
                        </div>
                    </div>
                </div>
                {/* //top header */}
                {/* middle header */}


                <nav className="navbar navbar-expand-sm bg-light">
                    <div className="container-fluid">
                        <a className="navbar-brand" href="/">
                            <img src="assets/images/scai_weblogo.png" width="200" alt="" />
                        </a>
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapsibleNavbar"
                            aria-controls="collapsibleNavbar"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <i className="fa fa-caret-down"></i>
                        </button>
                        <div className="collapse navbar-collapse" id="collapsibleNavbar">
                            <ul className="navbar-nav ms-auto">
                                <li className="nav-item d-none d-sm-block">
                                    <a className="nav-link" href="mailto:contact@arjtechnologies.com">
                                        <i className="fa fa-envelope"></i>
                                        <span className="fs-6 d-inline d-sm-none d-md-inline">
                                            contact@arjtechnologies.com
                                        </span>
                                    </a>
                                </li>
                                <li className="nav-item d-none d-sm-block">
                                    <a className="nav-link" href="#">
                                        <i className="fa fa-phone"></i>
                                        <span className="d-inline d-sm-none d-md-inline">+91 7733095696</span>
                                    </a>
                                </li>
                                <li className="nav-item mt-4">
                                    <button className="nav-link bttn btn btn-primary btn-sm" data-bs-toggle="modal"
                                        data-bs-target="#exampleModal">SCAI Demo</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>


                {/* //middle header */}
            </header>
            {/* //header */}
            {/* form popup  */}
            {showModal && (
                <div className="modal show" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h2 className="modal-title fs-5" id="exampleModalLabel">
                                    <center>Software Demo Registration</center>
                                </h2>
                                <button
                                    type="button"
                                    className="btn-close btn btn-info"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => setShowModal(false)}
                                >
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                </button>
                            </div>
                            <div className="modal-body">
                                {formError && (
                                    <div className="alert alert-danger" role="alert">
                                        {formError}
                                    </div>
                                )}
                                {message && (
                                    <div className="alert alert-success" role="alert">
                                        {message}
                                    </div>
                                )}
                                <div className="form-floating form-floating-outline mb-3">
                                    <label htmlFor="floatingInput">Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Name"
                                        onKeyUp={(e) => setName(e.target.value)}
                                    />
                                </div>
                                <div className="form-floating form-floating-outline mb-3">
                                    <label htmlFor="floatingInput">Email address</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        placeholder="Email"
                                        onKeyUp={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                                <div className="form-floating form-floating-outline mb-3">
                                    <label htmlFor="floatingInput">Mobile</label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        placeholder="Mobile"
                                        required
                                        onKeyUp={(e) => setMobile(e.target.value)}
                                    />
                                </div>
                                <div className="form-floating form-floating-outline mb-3">
                                    <label htmlFor="floatingInput">Your Message</label>
                                    <textarea
                                        placeholder="Your Message"
                                        className="form-control"
                                        onKeyUp={(e) => setDescription(e.target.value)}
                                    ></textarea>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                    onClick={() => setShowModal(false)}
                                >
                                    Close
                                </button>
                                <button
                                    type="button"
                                    onClick={formSubmit}
                                    className="btn btn-info"
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {/* form popup  // */}

        </>
    )
}

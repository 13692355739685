import React, { useState, useEffect } from "react";
import Footer from "../main/Footer";
import Header from "../main/Header";
import Slider from "react-slick";
export default function Home() {
  

  return (
    <>
      <Header />

      {/* banner */}
      <div className="container mt-5 mb-5">
        <div class="privacy-policy-container">
          <header>
            <h1>Privacy Policy</h1>
            <p>
              <strong>Last Updated:</strong> [Insert Date]
            </p>
          </header>
          <section>
            <h2>1. Information We Collect</h2>
            <p>We collect the following types of information:</p>
            <ul>
              <li>
                <strong>Customer Information:</strong> Institution name,
                address, contact details, and account information.
              </li>
              <li>
                <strong>Student and Staff Information:</strong> Names, grades,
                attendance records, and contact details.
              </li>
              <li>
                <strong>WhatsApp Messaging Data:</strong> Message templates,
                content, and delivery logs.
              </li>
              <li>
                <strong>Technical Information:</strong> IP addresses, browser
                types, and system logs.
              </li>
            </ul>
          </section>
          <section>
            <h2>2. How We Use the Information</h2>
            <p>We use the information to:</p>
            <ul>
              <li>Provide and maintain the SCAI software.</li>
              <li>Facilitate WhatsApp Messaging Services for notifications.</li>
              <li>
                Allow customers to create and manage WhatsApp message templates.
              </li>
              <li>Ensure compliance with WhatsApp's policies.</li>
            </ul>
          </section>
          <section>
            <h2>3. Data Sharing and Disclosure</h2>
            <p>We do not sell or rent data. Data may be shared with:</p>
            <ul>
              <li>
                <strong>WhatsApp:</strong> For message delivery.
              </li>
              <li>
                <strong>Service Providers:</strong> For software operations
                support.
              </li>
              <li>
                <strong>Legal Requirements:</strong> As required by law.
              </li>
            </ul>
          </section>
          <section>
            <h2>4. Data Security</h2>
            <p>
              We take measures to safeguard data, including encryption,
              restricted access, and regular security audits.
            </p>
          </section>
          <section>
            <h2>5. Data Retention</h2>
            <p>
              Data is retained as long as necessary for outlined purposes or as
              required by law. Users can request data deletion.
            </p>
          </section>
          <section>
            <h2>6. User Rights</h2>
            <p>
              Users have the right to access, update, and delete their data.
              Contact us at{" "}
              <a href="mailto:support@arjtechnologies.com">
                support@arjtechnologies.com
              </a>
              .
            </p>
          </section>
          <section>
            <h2>7. Third-Party Services</h2>
            <p>
              Our integration with WhatsApp involves third-party APIs governed
              by their privacy policies.
            </p>
          </section>
          <section>
            <h2>8. Children’s Privacy</h2>
            <p>
              We rely on institutions to ensure appropriate permissions are
              obtained for minors.
            </p>
          </section>
          <section>
            <h2>9. Changes to This Privacy Policy</h2>
            <p>
              We may update this Privacy Policy and notify users of significant
              updates through our software or via email.
            </p>
          </section>
          <section>
            <h2>10. Contact Us</h2>
            <p>If you have any questions, contact us:</p>
            <ul>
              <li>
                <strong>Email:</strong>{" "}
                <a href="mailto:support@arjtechnologies.com">
                  support@arjtechnologies.com
                </a>
              </li>
              <li>
                <strong>Phone:</strong> [Insert Phone Number]
              </li>
              <li>
                <strong>Website:</strong>{" "}
                <a href="https://www.arjtechnologies.com" target="_blank">
                  www.arjtechnologies.com
                </a>
              </li>
            </ul>
          </section>
        </div>
      </div>

      <Footer />
    </>
  );
}

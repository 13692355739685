import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import Home from "./pages/Home";
import PrivacyPolicy from "./pages/PrivacyPolicy";


export default function App() {
  return (
    <Router>
      
        <Routes>
          <Route path="/" element={<Home />} /> 
          <Route path="/privacy-policy" element={<PrivacyPolicy/>} /> 
        </Routes>
     
    </Router>
  );
}
